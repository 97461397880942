import './stock.css';
import Footer from '../../components/Footer';
import { useQuery } from '@apollo/client';
import { QUERY_CURRENT_PRICE, GET_STOCK_HISTORY, GET_LOW_COST_OPTIONS } from '../../utils/queries';
import DisplayStockInfo from './DisplayStockInfo';
import DisplayOption from './DisplayOption';
import StockChart from './StockChart';
import DisplayLowCostOptions from './DisplayLowCostOptions';
import LoadingComponent from './LoadingComponent';
import Patron from './Patron/Patron';

const Stock = ({ symbol, loggedin, isSubscribed }) => {
    const currentPriceQuery = useQuery(QUERY_CURRENT_PRICE, { variables: { symbol: symbol } });
    const stockHistoryQuery = useQuery(GET_STOCK_HISTORY, { variables: { symbol: symbol, timeframe: 'Daily' } });
    const lowCostOptionsQuery = useQuery(GET_LOW_COST_OPTIONS, { variables: { symbol: symbol } });
    if (currentPriceQuery.loading || stockHistoryQuery.loading || lowCostOptionsQuery.loading) {
        return (<>
            <LoadingComponent symbol={symbol} />
        </>);
    }
    const currentPrice = currentPriceQuery.data.getCurrentPrice.currentPrice ?? 0; 

    return (<div>
        <div className="company-title">
            <h2>{symbol}</h2>
            <h2></h2>
            <h2>CIK - </h2>
            <h2>{currentPrice}</h2>
        </div>
        {/* <Patron /> */}
        
        <DisplayLowCostOptions symbol={symbol} data={lowCostOptionsQuery.data.getLowCostOptions} />
        <DisplayOption symbol={symbol} />
        <Footer />
    </div>);
}

export default Stock;