import { useQuery } from '@apollo/client';
import { GET_OPTIONCHAIN } from '../../utils/queries';
import { timeOptions } from '../../utils/timeOptions';
import React, { useState } from 'react';

import OptionChainCards from './OptionChainCards';
import OptionContract from './OptionChain/OptionContract';

const DisplayOption = ({ symbol }) => {
    const optionQuery = useQuery(GET_OPTIONCHAIN, { 
        variables: { symbol: symbol }
    });

    
    if (optionQuery.loading) {
        return (<></>);
    }
    const options = optionQuery.data.getOptionChain;
  
    const sortedOptions = [...options].sort((a, b) => {
        const aVolume = a.lastFiveDays[0]?.v || 0;
        const bVolume = b.lastFiveDays[0]?.v || 0;
        return bVolume - aVolume;
    });
    const optionChain = [];
    for (let i in sortedOptions) {
        optionChain.push(<OptionContract key={`OptionContract-${i}`} data={sortedOptions[i]} />);
    }

    return (<>
        <h3 style={{ textAlign: "center", display: "block", margin: "0 auto", border: "solid 1px black", maxWidth: "800px", backgroundColor: "lightgray" }}>Option Chain</h3>
        <div className="optionchain-container">
            <div className="optionchain">
                {optionChain}
            </div>
            
        </div>
    </>);
}

export default DisplayOption;